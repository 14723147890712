/****ESTILOS PARA EL MENÚ DE NAVEGACION****/
body {
  margin: 0;
  padding: 0;
  background-image: url('../imagenes//tigo_option6.jpg'); /* Ruta desde public/ */
  background-size: cover; /* Cubre toda la pantalla */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita repetición */
  background-attachment: fixed; /* Fondo fijo al hacer scroll (opcional) */
  min-height: 100vh; /* Altura mínima = viewport */
}

.main1{
  margin: 0px;
  padding: 0px;
  font-family: Arial, sans-serif;
}

.navbar1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 10px 20px;
}
.nav-links1{
  margin: 0;
  display: flex;
  gap: 20px;
  list-style: none;
}
.nav-links2 {
  flex-direction: column;
  position: absolute;
  list-style: none;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: #333;
  margin: 0;
  padding-top: 10px;
  text-align: center;
  transition: all 0.3;
}
.links1 {
  color: white;
  text-decoration: none;
  transition: color 0.3;
  font-size: 18px;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.links1:hover {
  color: #f39c12;
}
.logo {
  font-size: 24px;
  font-weight: bold;
}

.line {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 3px 0;
}
.burger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}
@media screen and (max-width: 750px){
  .navbar1 {
    position: relative;
  }
  .burger {
    display: flex;
  }
  .nav-links1 {
    display: none;
  }
  .links1 {
    color: white;
    text-decoration: none;
    transition: color 0.3;
    font-size: 22px;
  }
}

/*ESTILOS PARA EL LOGIN*/
.App {
    text-align: center;
    padding: 40px 30px;
    max-width: 100% !important;
  }
  .container-fluid {
    max-width: 400px;
    margin-top: 100px;
  }
  /*ESTILOS PARA EL BOTON DESPLEGABLE DE OPCIONES*/
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    background-color: #60676e;
    color: white;
    padding: 8px 12px;
    font-size: 16px;
    border: none;
    border-radius: 10%;
    cursor: pointer;
  }
  
  .dropdown-content {
    position: absolute;
    background-color: #343a40;
    min-width: 100px;
    inset: 0px auto auto 0px;
    transform: translate3d(-100px, 50px, 0px);
    padding: 1rem;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }